import { graphql, Link } from "gatsby"

import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import EmblaCarousel from "../components/EmblaCarousel";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="SSB Photography" />

      <EmblaCarousel slides={data.cover.gallery} />

      <div className="text-center mx-auto my-20">
        <Link
          to="/gallery"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-4 px-6 border border-blue-500 hover:border-transparent rounded-full">
          View the gallery
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage

export const site = graphql`
  query HomeQuery {
    cover: contentfulPortfolio(slug: {eq: "cover"}) {
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

/*
<h3 className="text-3xl text-center font-bold mt-16">Now Booking Fall Mini Sessions!</h3>
<div className="text-center">
  <a className="text-center text-blue-700" href="https://docs.google.com/forms/d/e/1FAIpQLSeuV6BurE8SUKqfG7Ux7oVlGy1ClQOd77ewGYp-IixZEtxLdg/viewform" target="_blank" rel="noreferrer">click here for details</a>
</div>
*/
